/* https://typescale.com
    SCALE: 1.250 

    7.451rem / 119.21px
    5.96rem  / 95.37px
    4.768rem / 76.29px
    3.815rem / 61.04px  
    3.052rem / 48.83px  
    2.441rem / 39.06px  
    1.953rem / 31.25px  
    1.563rem / 25.00px  
    1.25rem  / 20.00px  
    1rem     / 16.00px  
    0.8rem   / 12.80px  
    0.64rem  / 10.24px  
    0.512rem / 8.19px   
    0.41rem  / 6.55px   
    0.328rem / 5.24px   
    0.262rem / 4.19px   
    0.21rem  / 3.36px   
    0.168rem / 2.68px   
    0.134rem / 2.15px   
    0.107rem / 1.72px
*/

.videoContainer {
	padding-top: 56.25%;
    height: 0px;
    position: relative;   
}

.iFrameResponsive {
	padding: 0 0.976rem; 
	width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.videoDetails {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    padding-left: 0.976rem; 
    padding-bottom: 3rem;
}

.videoDetails span, .imdb {
    color: #efe9e0;
    display: block;
}
.title {
    padding: 0 0.976rem; 
    margin: 1rem 0;
    width: 100%;
    display: block;
    font-size: 1.953rem;
    color: #efe9e0;
}

.bottom {
    font-size: 1rem;
}
.top {
    font-weight: 200;
    font-size: 0.8rem;
    margin-bottom: -5px;
}
.back {
    color: #efe9e0;
    font-weight: 100;
    font-size: 0.8rem;
    margin-top: 1rem;
    cursor: pointer;
}


@media only screen and (max-width: 768px) {
    .iFrameResponsive {
        padding: 0 0.64rem; 
    }
    .videoDetails { 
        gap: 0.328rem; 
        padding-left: 0.64rem;
        padding-right: 0.64rem;
    }
    .title { 
        padding: 0 0.64rem; 
        font-size: 1.563rem; 
    }
    .bottom { font-size: 1rem; }
    .top { font-size: 0.8rem; }
}

/*@media only screen and (max-width: 430px) {

}
*/
@media only screen and (max-width: 375px) {
    .title { font-size: 1.25rem; }
    .bottom { font-size: 0.8rem; }
    .top { font-size: 0.64rem; }
    
}

@media only screen and (max-width: 320px) {
    .title { font-size: 1.25rem; }
}