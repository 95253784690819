.img {
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	z-index: -2;
	transition: opacity 1.2s;
	animation: fadeInAnimation ease-in-out 0.3s;
}

.img.show {
	position: absolute;
	opacity: 1;
	z-index: -1;
	width: 100%;
	height: 100vh;
	object-fit: cover;
	transition: opacity 1.2s;
}

.show ~ .mask {
	cursor: pointer;
	position: absolute;
	left: 0;
	top:0;
	background-image: linear-gradient(0deg, #00000000 60%, #000000dd 100%);
	height: 100%;
	width: 100%;
	z-index: 0;
}




