.img {
	overflow: hidden;
}

.img img {
	display: block;
	transition: all 0.3s;
	width: 100%;
}

.img img:hover  {
	cursor: pointer;
	filter: brightness(0.7);
}
