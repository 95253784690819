.login {
	padding-top: 4.768rem;
	display: flex;
	flex-direction: column;
	gap: 0.8rem;
	height: calc(100vh - 4.768rem);
	justify-content: center;
	align-items: center;
}

.inputGroup {
	display: flex;
	flex-direction: column;
	width: 70%;
}

.inputGroup label {
	color: #efe9e0;
}

.inputGroup input {
	padding: 0.41rem;
}

.inputGroup input:focus {
	outline: none;
}

.inputGroup button {
	cursor: pointer;
	padding: 0.41rem;
	color: #000;
	border: none;
	border-radius: 0.134rem;
	-webkit-appearance: none;
}

.loginErr {
	text-align: center;
	color: #ff2222;
/*	color: #efe9e0;*/
	font-weight: 400;
}


@media only screen and (min-width: 1025px) { 
	.inputGroup { 
		width: 20%; 
	} 
}
@media only screen and (min-width: 1440px) {
 	.inputGroup { 
 		width: 12%; 
 	} 
}

@media (min-width: 769px) and (max-width: 1024px) {
	.inputGroup {
		width: 28%; 
	} 
}

@media only screen and (max-width: 768px) {
 	.inputGroup { 
 		width: 40%; 
 	} 
}

@media only screen and (max-width: 430px) {
 	.inputGroup { 
 		width: 70%; 
 	} 
}

@media only screen and (max-width: 475px) {
 	.inputGroup { 
 		width: 80%; 
 	} 
}

@media only screen and (max-width: 320px) {
 	.inputGroup { 
 		width: 80%; 
 	} 
}



