.inputGroup {
	display: flex;
	flex-direction: column;
}

.inputGroup label {
	cursor: pointer;
	color: #efe9e0;
}

.inputGroup input {
	outline: none;
	border: none;
	border-radius: 0.134rem;
	padding-left: 0.262rem;
	height: 1.953rem;
	transition: all 0.3s;
}

.inputGroup input:focus {
	outline: none;
	border: 0px solid #000;
	transition: all 0.3s;
}

.inputGroup input:invalid {
    outline: none;
	background-color: #922724;
    transition: all 0.3s;
}
@media only screen and (max-width: 430px) {
	.inputGroup input {
		height: 1.563rem;
	}

	.inputGroup label {
		font-size: 0.8rem;
	}
}


.actionButtons {
	flex-direction: row;
	justify-content: space-between;
	gap: 1rem;
}

.submitBtn, .cancelBtn {
	cursor: pointer;
	height: 1.953rem;
	width: 20%;
	-webkit-appearance: none;
}
.cancelBtn {
	border: none;
	border-radius: 0.134rem;
	color: #000;
}
.submitBtn {
	border: none;
	border-radius: 0.134rem;
	background-color: #4F7942;
	color: #efe9e0;
	-webkit-appearance: none;
}

.submitBtn:active, .cancelBtn:active { 
	transform: scale(0.995); 
	-webkit-appearance: none;
}

.submitBtn:hover {
	background-color: #3e6831;
	-webkit-appearance: none;
}

.submitBtn:disabled,
.submitBtn:disabled:hover {
	cursor: wait;
	color: #ccc;
	background-color: #888;
	-webkit-appearance: none;
}

/*---M-E-D-I-A---Q-U-E-R-I-E-S---*/
@media only screen and (max-width: 768px) {
	.submitBtn, .cancelBtn { 
		width: 25%; 
		-webkit-appearance: none;
	}
}

@media only screen and (max-width: 430px) {	
	.submitBtn, .cancelBtn {
		height: 1.563rem;
		-webkit-appearance: none;
	}
	.submitBtn, .cancelBtn { flex-grow: 1; }
}


