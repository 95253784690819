.inputGroup {
	display: flex;
	flex-direction: column;
}

.inputGroup label {
	cursor: pointer;
	color: #efe9e0;
}

.inputGroup input {
	outline: none;
	border: none;
	border-radius: 0.134rem;
	padding-left: 0.262rem;
	height: 1.953rem;
	transition: all 0.3s;
}

.inputGroup input:focus {
	outline: none;
	border: 0px solid #000;
	transition: all 0.3s;
}

.inputGroup input:invalid {
    outline: none;
	background-color: #922724;
    transition: all 0.3s;
}
@media only screen and (max-width: 430px) {
	.inputGroup input {
		height: 1.563rem;
	}

	.inputGroup label {
		font-size: 0.8rem;
	}
}






.fileUploadInput input { display: none; }

.fileUploadInput button {
	cursor: pointer;
	height: 1.953rem;
	background-color: #107ab0;
	border: none;
	border-radius: 0.134rem;
	color: #efe9e0;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.41rem;
	width: 40%;
}

.fileUploadInput button:active { 
	transform: scale(0.995); 
}
.fileUploadInput button:hover {
	background-color: #006aa0;
}

.fileUploadInput .icon {
	font-size: 1rem;
	color: #efe9e0; 
}

.fileName {
	padding-top: 0.268rem;
	font-size: 0.8rem;
	font-weight: 300;
	color: #efe9e0; 
}

/*---M-E-D-I-A---Q-U-E-R-I-E-S---*/
@media only screen and (max-width: 768px) {
	.fileUploadInput button { width: 50%; }
}

@media only screen and (max-width: 430px) {
	.fileUploadInput button { height: 1.563rem; }
	.fileUploadInput button { width: 100%; }
	.fileUploadInput .icon { font-size: 0.8rem; }
	.fileName { font-size: 0.64rem; }
}


