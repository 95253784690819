.spinnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin-top: calc(-4.768rem);
}

.loader {
    width: 48px;
    height: 48px;
    border: 5px solid;
    border-color: #efe9e0 transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}