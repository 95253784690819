.main {
/*	animation: fadeInBackground ease-in-out 0.8s;*/
	width: 100%;
	height: 100vh;
    padding-top: 4.768rem;
}

@keyframes fadeInBackground {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}