.form {
	display: flex;
	flex-direction: column;
	margin: 1rem;
	gap: 1rem;
}

@media only screen and (max-width: 430px) {
	.form {
		margin: 0.8rem;
		gap: 0.8rem;
	}
}

@media only screen and (max-width: 375px) {
	.form {
		margin: 0.512rem;
	}
}

