.nav {
	position: absolute;
	z-index: 2;
	display: flex;
	width: 100%;
	padding: 0 1.953rem;
	justify-content: space-between;
	align-items: center;
	height: 4.768rem;
}

h1 {
	color: #efe9e0;
}

@media (min-width: 769px) and (max-width: 1024px) {
	.nav { padding: 0 0.976rem; }
	.nav a { font-size: 1rem; }
	.navName {
		font-size: 0.8rem;
		line-height: 140%;
	}
}

@media only screen and (max-width: 768px) {
/*	.nav { padding: 0 0.976rem; }*/
	.nav { padding: 0 0.64rem; }
	.nav .responsive {
		border: none !important;
		background: linear-gradient(#00000000 10%, #00000099) !important;
	}
}

a {
	text-decoration: none;
	color: #efe9e0;
}

.about_me {
	display: flex;
	justify-content: center;
	align-items: center;
	height: calc(100vh);
	margin-top: calc(-4.768rem);
}

.aboutMeImg {	
	opacity: 0.5;
	object-fit: cover;
	width: 100%;
	height: 100%;
}

.aboutMe {
	top: 50%;
	right: 50%;
	transform: translate(50%, -50%);
	position: absolute;
	display: flex;
	flex-direction: column;
	/* align-items: center; */
	gap: 20px;
	animation: fadeInBackground ease-in-out 0.8s;
}

.contactItem {
	display: flex;
	align-items: center;
	gap: 10px;
	letter-spacing: 1px;
}


.icon {
	display: flex;
	justify-content: center;
	/* align-items: center; */
	color: #efe9e0;
	/* transition: all 0.3s; */
	/* font-size:1.953rem; */
}


.js_cv {
	width: 100%;
	height: auto;
	max-width: 28px;
	background: #fff;
}

.imdb {
	width: 100%;
 	height: auto;
 	max-width: 58px;
 	background: #fff;
}
/* .aboutMe h1 {
	letter-spacing: 7px;
	font-size: 3rem;
	animation: fadeInBackground ease-in-out 0.8s;
} */

@keyframes fadeInBackground {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
}