.videos {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 0.976rem;
	padding: 0 0.976rem;
/*	padding-top: 4.768rem;*/
}


@media only screen and (max-width: 768px) {
	.videos {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		gap: 0.64rem;
		padding: 0 0.64rem;
/*		padding-top: 4.768rem;*/
	}
}
