.photosContainer {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 0.976rem;
	padding: 0 0.976rem;
	animation: photoFadeIn ease-in-out 0.5s
}

@keyframes photoFadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media only screen and (min-width: 2560px) {
	.photosContainer {
		grid-template-columns: repeat(4, 1fr);
	}
}

@media (min-width: 769px) and (max-width: 1024px) {
	.photosContainer {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media only screen and (max-width: 768px) {
	.photosContainer {
		grid-template-columns: repeat(1, 1fr);
	}
}

@media only screen and (max-width: 430px) {
	.photosContainer {
		grid-template-columns: repeat(1, 1fr);
	}
}

