.inputGroup {
	display: flex;
	flex-direction: column;
}

.inputGroup label {
	cursor: pointer;
	color: #efe9e0;
}

.inputGroup input {
	outline: none;
	border: none;
	border-radius: 0.134rem;
	padding-left: 0.262rem;
	height: 1.953rem;
	transition: all 0.3s;
}

.inputGroup input:focus {
	outline: none;
	border: 0px solid #000;
	transition: all 0.3s;
}

.inputGroup input:invalid {
    outline: none;
	background-color: #922724;
    transition: all 0.3s;
}
@media only screen and (max-width: 430px) {
	.inputGroup input {
		height: 1.563rem;
	}

	.inputGroup label {
		font-size: 0.8rem;
	}
}
