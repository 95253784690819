.videoContainer {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	padding: 0 0.976rem;
}

@media only screen and (max-width: 768px) {
	.videoContainer {
		gap: 0.64rem;
		padding: 0 0.64rem;
	}
}

/*@media only screen and (max-width: 430px) {

}*/

/*@media only screen and (max-width: 375px) {

}*/

@media only screen and (max-width: 320px) {
	.videoContainer { gap: 0.41rem; }
}


/* 
	https://typescale.com
	SCALE: 1.250 

	11.642rem/	
	9.313rem /
	7.451rem / 119.21px
	5.96rem  / 95.37px
	4.768rem / 76.29px
	3.815rem / 61.04px	
	3.052rem / 48.83px	
	2.441rem / 39.06px	
	1.953rem / 31.25px	
	1.563rem / 25.00px	
	1.25rem  / 20.00px	
	1rem 	 / 16.00px	
	0.8rem   / 12.80px	
	0.64rem  / 10.24px	
	0.512rem / 8.19px	
	0.41rem  / 6.55px	
	0.328rem / 5.24px	
	0.262rem / 4.19px	
	0.21rem  / 3.36px	
	0.168rem / 2.68px	
	0.134rem / 2.15px	
	0.107rem / 1.72px
	0.086rem /1.37px
	0.069rem /1.10px
*/