/* https://typescale.com
	SCALE: 1.250 

	7.451rem / 119.21px
	5.96rem  / 95.37px
	4.768rem / 76.29px
	3.815rem / 61.04px	
	3.052rem / 48.83px	
	2.441rem / 39.06px	
	1.953rem / 31.25px	
	1.563rem / 25.00px	
	1.25rem  / 20.00px	
	1rem 	 / 16.00px	
	0.8rem   / 12.80px	
	0.64rem  / 10.24px	
	0.512rem / 8.19px	
	0.41rem  / 6.55px	
	0.328rem / 5.24px	
	0.262rem / 4.19px	
	0.21rem  / 3.36px	
	0.168rem / 2.68px	
	0.134rem / 2.15px	
	0.107rem / 1.72px
*/


.notFound {
	display: flex;
	gap: 1.56rem;
	height: 100vh;
	flex-direction: column;
	color: #efe9e0;
	margin-top: 180px;
	align-items: center;

}

.fourofour {
	font-size: 3.815rem;
	letter-spacing: 0.134rem;
	font-weight: 300;
	line-height: 100%;
}

.pageNotFound {
	font-size: 1.563rem;
	font-weight: 200;
	letter-spacing: 0.134rem;
	line-height: 100%;
}

.returnHomePage {
	cursor: pointer;
	font-size: 1.25rem;
	font-weight: 200;
	line-height: 100%;
	letter-spacing: 0.134rem;
	color: #999;
	transition: all 0.3s;
	padding-bottom: 3px;
}

.returnHomePage:hover {
	color: #efe9e0;
	border-bottom: 0.5px solid #efe9e0;
	padding-bottom: 3px;
	transition: all 0.3s;
}