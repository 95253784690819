.modal {}

.overlay {
	display: flex;
	position: fixed;
	z-index: 3;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #000c;
	color: #efe9e0;
}

.modalContent {
	position: absolute;
	z-index: 4;
	border-radius: 0.134rem;
	background-color: #16161D;  
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	min-height: 642px;
}

.modalCloseIcon { 
	cursor: pointer;
	position: absolute;
	top: 0.512rem;
	right: 0.512rem;
	color: #efe9e0; 
	font-size: 1.953rem;
}

.modalTitle {
	text-align: center;
	margin-top: 1rem;
	font-size: 1.25rem;
	font-weight: 200;
}




/*--------M-E-D-I-A---Q-U-E-R-I-E-S--------*/
@media only screen and (min-width: 1025px) {
	.modalContent {
		width: 55%;
	}
}
@media only screen and (min-width: 1440px) {
	.modalContent {
		width: 35%;
	}
}



@media (min-width: 769px) and (max-width: 1024px) {
	.modalContent {
		width: 70%;
	}
}

@media only screen and (max-width: 768px) {
	.modalContent {
		width: 70%;
		top: 5%;
		left: 50%;
		transform: translate(-50%, 0%);
	}
}

@media only screen and (max-width: 430px) {
	.modalContent { 
		width: 92%; 
		top: 5%;
		left: 50%;
		transform: translate(-50%, 0%);
	}
	.modalTitle {
		margin-top: 0.328rem;
		font-size: 1rem;
		font-weight: 200;
	}
	.modalCloseIcon { 
		font-size: 1.563rem; 
	}
}

@media only screen and (max-width: 375px) {
	.modalContent { 
		width: 96%; 
	}
}

/*@media only screen and (max-width: 320px) {

}*/


/* https://typescale.com
	SCALE: 1.250 

	7.451rem / 119.21px
	5.96rem  / 95.37px
	4.768rem / 76.29px
	3.815rem / 61.04px	
	3.052rem / 48.83px	
	2.441rem / 39.06px	
	1.953rem / 31.25px	
	1.563rem / 25.00px	
	1.25rem  / 20.00px	
	1rem 	 / 16.00px	
	0.8rem   / 12.80px	
	0.64rem  / 10.24px	
	0.512rem / 8.19px	
	0.41rem  / 6.55px	
	0.328rem / 5.24px	
	0.262rem / 4.19px	
	0.21rem  / 3.36px	
	0.168rem / 2.68px	
	0.134rem / 2.15px	
	0.107rem / 1.72px
	0.086rem /1.37px
	0.069rem /1.10px
*/