.formContainer  {

	display: flex;
	flex-direction: column;
	width: 100%;
	margin: 0 auto;
/*	align-items: center;*/
/*	gap: 1rem;*/
}

@media only screen and (min-width: 1025px) {
	.formContainer { width: 55%; }
}
@media only screen and (min-width: 1440px) {
	.formContainer { width: 35%; }
}

@media (min-width: 769px) and (max-width: 1024px) {
	.formContainer { width: 85%; }
}



/*@media only screen and (max-width: 768px) {};*/
@media only screen and (max-width: 430px) {
	.ipnutGroup { width: 100%; padding: 0 0.64rem; }
};
/*@media only screen and (max-width: 375px) {};*/
/*@media only screen and (max-width: 320px) {};*/
