/* https://typescale.com
	SCALE: 1.250 

	7.451rem / 119.21px
	5.96rem  / 95.37px
	4.768rem / 76.29px
	3.815rem / 61.04px	
	3.052rem / 48.83px	
	2.441rem / 39.06px	
	1.953rem / 31.25px	
	1.563rem / 25.00px	
	1.25rem  / 20.00px	
	1rem 	 / 16.00px	
	0.8rem   / 12.80px	
	0.64rem  / 10.24px	
	0.512rem / 8.19px	
	0.41rem  / 6.55px	
	0.328rem / 5.24px	
	0.262rem / 4.19px	
	0.21rem  / 3.36px	
	0.168rem / 2.68px	
	0.134rem / 2.15px	
	0.107rem / 1.72px
*/

.video {
	cursor: pointer;
	position: relative;
	animation: fadeInBackground ease-in-out 0.5s;
	width: 100%;
}

@keyframes fadeInBackground {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


.video:hover img {
	filter: brightness(0.2);	
}

.video img {
	width: 100%;
	display: block;
	transition: all 0.3s;
	border-radius: 0.134rem;
}

.videoDescription {
	display: none;
	width: 100%;
	color: #efe9e0;
	position: absolute;
	display: flex;
	flex-direction: column;
	gap: 2px;
	align-items: center;
	top: 45%;
	left: 50%;
	transform: translate(-50%, -50%);
	transition: opacity 0.4s;
	opacity: 0;
}
.videoDescriptionResponsive {
	display: none;
}


.video:hover .videoDescription {
	width: 100%;
	display: flex;
	opacity: 1;
	transition: 0.4s;
	top: 52%;
}



.videoTitle {
	font-size: 1.563rem;
	font-weight: 300;
	letter-spacing: 1px;
	margin-bottom: 2px;
}

.videoDirector, .videoCamera, .videoColor {
	font-size: 0.8rem;
	letter-spacing: 0.5px;
}

.videoDirector span, 
.videoCamera span,
.videoColor span {
	font-size: 0.8rem;
	font-weight: 200;
}

@media only screen and (max-width: 768px) {
	.videoDescriptionResponsive {
		display: flex;
		flex-direction: column;

		margin-bottom: 1rem;
		gap: 0.168rem;
		color: #efe9e0;
		font-size: 1rem;
	}
	
}

@media only screen and (max-width: 430px) {

	.videoDirector, .videoCamera, .videoColor {
		font-size: 0.64rem;
		letter-spacing: 0.5px;
	}
	.videoDirector span, 
	.videoCamera span,
	.videoColor span {
		font-size: 0.512rem;
		font-weight: 200;
	}
}

@media only screen and (max-width: 375px) {
	.videoTitle { 
		font-size: 1.25rem;
	}
	.videoDirector, .videoCamera, .videoColor {
		font-size: 0.512rem;
		letter-spacing: 0.5px;
	}
	.videoDirector span, 
	.videoCamera span,
	.videoColor span {
		font-size: 0.41rem;
		font-weight: 200;
	}
}

@media only screen and (max-width: 320px) {
	.videoTitle { 
		font-size: 1rem;
	}
}