/* https://typescale.com
	SCALE: 1.250 

	7.451rem / 119.21px
	5.96rem  / 95.37px
	4.768rem / 76.29px
	3.815rem / 61.04px	
	3.052rem / 48.83px	
	2.441rem / 39.06px	
	1.953rem / 31.25px	
	1.563rem / 25.00px	
	1.25rem  / 20.00px	
	1rem 	 / 16.00px	
	0.8rem   / 12.80px	
	0.64rem  / 10.24px	
	0.512rem / 8.19px	
	0.41rem  / 6.55px	
	0.328rem / 5.24px	
	0.262rem / 4.19px	
	0.21rem  / 3.36px	
	0.168rem / 2.68px	
	0.134rem / 2.15px	
	0.107rem / 1.72px
	0.086rem /1.37px
	0.069rem /1.10px
*/

.nav {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	height: 4.768rem;
	position: fixed;
 	z-index: 3;
/* 	background-color: #000;*/

 	
/* 	background-color:  #0C090A;*/

/*	sita baika*/
/*	background-color: #0F0F0F;*/

/* 	background-color: #1B1212;*/

/*	sita baika*/
/* 	background-color: #1A1110;*/

/*	sita baika*/
	background-color: #16161D;  

/*	background-color: #353935;*/
/*	background-color: #100C08;*/
/*	background-color: #28282B;*/
/*	background-color: #282C35;*/
/*	background-color: #34282C;*/
/*	background-color: #36454F;*/

}

.nav ul { list-style: none; }

.navName {
	color: #efe9e0;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	letter-spacing: 0.168rem;
}

.lac {
	font-size: 1rem;
	margin-left: 0.41rem;
}

.cinematographer {
	font-weight: 100;
	font-size: 0.8rem;
}

.navItems {
	display: flex;
	gap: 1.25rem;
}

.testMoviesLink {
	cursor: pointer;
	color: #efe9e0;
}


.nav a,
.nav a:link,
.nav a:visited {
  	text-decoration: none;
	font-weight: 200;
	letter-spacing: 0.134rem;
/*	letter-spacing: 0.069rem;*/
  	color: #efe9e0;
  	transition: color 0.3s;
}

.nav a:hover, 
.nav a:active {
	color: #a5a5a5;
	transition: color 0.3s;
}

.nav a:global(.active) { color: #a5a5a5; }

.bars {
	display: none;
	position: relative;
	flex-direction: column;
	gap: 8px;
	height: 30px;
	justify-content: center;
	cursor: pointer;
}
.bar {
	color: white;
	width: 1.953rem;
	border-bottom: 2px solid  #efe9e0;
	opacity: 1;
	transition: all 0.3s;
}

.show .barTop {
	position: absolute;
	transform: rotate(45deg);
	transition: all 0.3s;
}

.show .barMid {
	display: none;
	transition: all 0.3s;
}

.show .barBottom {
	transform: rotate(-45deg);
	transition: all 0.3s;
}

.nav ul.responsive.showNav {
	opacity: 1;
	height: 220px;
	transition: all 0.3s;
}

@media only screen and (min-width: 1025px) {
	.nav {
		padding: 0 1.953rem;
	}

	.nav a {
		font-size: 1.25rem;
	}

	.navName {
		font-size: 1rem;
		line-height: 115%;
	}
}



@media (min-width: 769px) and (max-width: 1024px) {
	.nav { padding: 0 0.976rem; }
	.nav a { font-size: 1rem; }
	.navName {
		font-size: 0.8rem;
		line-height: 140%;
	}
}

.navBackground { background-color: #16161D00; }


@media only screen and (max-width: 768px) {

	.nav { 
		padding: 0 0.64rem; 
	}
  	.nav ul.responsive { 
  		background-color: #16161D;
  		position: absolute;
  		z-index: 1;
  		overflow: hidden;
  		opacity: 0;
  		right: 0;
  		top: 76px;
  		height: 0;
  		width: 100vw;
  		flex-direction: column;
  		align-items: self-end;
  		padding-right: 10px;
  		gap: 0.512rem;
  		transition: all 0.3s;
  	}

  	.navBackground ul.responsive {
		background: linear-gradient(#00000000 10%, #00000099) !important;
	}

  	.responsive li a {
  		letter-spacing: 0.107rem !important;
  		font-weight: 300 !important;
  		font-size: 1.25rem;
  	}

  	.navName {
		font-size: 0.8rem;
		line-height: 140%;
	}

	.bars {
		display: flex;
	}
}

@media only screen and (max-width: 320px) {
	.navName h1 {
		font-size: 1rem;
	}

	.lac {
		font-size: 0.64rem;
	}

	.cinematographer {
		font-size: 0.64rem;
		line-height: 100%;
	}
}


