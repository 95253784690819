.video { 
	position: relative;
	display: flex; 
	opacity: 1;
}

.videoType {
	font-family: sans-serif;
	position: absolute;
	color: #efe9e0;;
	border-radius: 2px;
	padding: 0 6px;
	padding-bottom: 1px;
	font-size: 0.64rem;
	font-weight: 200;
	top: 4px;
	left: 153px;
}

:global(.movie) { background-color: #107ab0; }
:global(.commercial) { background-color: #228b22; }
:global(.music) { background-color: #c4a500; }
:global(.other) { background-color: #16161D; }

.deletedVideo {
	opacity: 0;
	transition: all 0.3s;
}

.img {
	display: block;
	width: 9.313rem;
	border-top-left-radius: 0.134rem;
	border-bottom-left-radius: 0.134rem;
}

.body {
	cursor: pointer;
	display: flex;
	align-items: center;
	color:  #000;
	flex: 1 0 0;
	border-top-left-radius: 0.134rem;
	border-bottom-left-radius: 0.134rem;
	background-color: #efe9e0;
}

.body:hover .title {
	color: #777;
}

.title { 
	margin-left: 1rem; 
	transition: color 0.3s;
}

.actions {
	padding-right: 1.563rem;
	display: flex;
	gap: 1.563rem;
	border-top-right-radius: 2px;
	border-bottom-right-radius: 2px;
	background-color: #efe9e0;
}

.actionBtn {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.filterVideoButtons {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.arrowIcon {
	color: #6f6960;
	cursor: pointer;
	font-size: 1.563rem;
}

.priorNumber {
	font-size: 0.8rem;
	font-weight: bold;
	color: #6f6960;
}

.delete .icon,
.delete .iconText {
	color: #a22;
}

.icon {
	display: flex;
	justify-content: center;
	align-items: center;
	color: #555;
	transition: all 0.3s;
	font-size:1.953rem;
}

.iconText {
	color: #555;
	position: absolute;
	bottom: 0.5rem;
	letter-spacing: 0.3px;
	font-weight: 400;
	font-size: 0.64rem;
	transition: color 0.3s;
}

@media(hover: hover) {
	.actionBtn:not(.delete):hover .icon,
	.actionBtn:not(.delete):hover .iconText {
		color: #008800;
		cursor: pointer;
	}

	.delete:hover .icon,
	.delete:hover .iconText {
		cursor: pointer;
		color: #aa0000;
	}
}

@media only screen and (max-width: 430px) {
	.videoType {
		font-size: 0.41rem;
		font-weight: 100;
		padding: 0 4px;
		top: 3px;
		left: 122px;
	}
	.img {
		width: 7.451rem;
	}
	.title {
		margin-left: 0.64rem; 
		font-size: 0.8rem;
		line-height: 130%;
	}
	.actions {
		padding-right: 1rem;
		gap: 1.25rem;
	}
	.icon {
		font-size: 1.25rem;
	}
	.iconText {
		font-weight: 400;
		font-size: 0.512rem;
		letter-spacing: 0;
	}
	.arrowIcon {
		font-size: 1.25rem;
	}

	.priorNumber {
		font-size: 0.512rem;
	}
}
@media only screen and (max-width: 375px) {
	.videoType {
		font-size: 0.41rem;
		font-weight: 100;
		padding: 0 3px;
		top: 3px;
		left: 98px;
	}
	.img { width: 5.96rem; }
	.title { margin-left: 0.512rem; }
	.actions {
		padding-right: 0.8rem;
		gap: 0.8rem;
	}
	.iconText {bottom: 0.328rem; }
	.arrowIcon {
		font-size: 0.8rem;
	}
}

@media only screen and (max-width: 320px) {
	.videoType {
		font-size: 0.328rem;
		font-weight: 200;
		padding: 0 2px;
		top: 2px;
		left: 78px;
	}
    .img { width: 4.768rem; }
    .title { 
    	font-size: 0.64rem;
    	margin-left: 0.41rem; 
    }
    .actions {
		padding-right: 0.41rem;
		gap: 0.8rem;
	}

	.icon {
		font-size: 1rem;
	}
	.iconText {
		font-size: 0.41rem;
		bottom: 0.168rem; 
	}

	.arrowIcon {
		font-size: 0.64rem;
	}
	.priorNumber {
		font-size: 0.41rem;
	}
}


/* 
	https://typescale.com
	SCALE: 1.250 

	11.642rem/	
	9.313rem /
	7.451rem / 119.21px
	5.96rem  / 95.37px
	4.768rem / 76.29px
	3.815rem / 61.04px	
	3.052rem / 48.83px	
	2.441rem / 39.06px	
	1.953rem / 31.25px	
	1.563rem / 25.00px	
	1.25rem  / 20.00px	
	1rem 	 / 16.00px	
	0.8rem   / 12.80px	
	0.64rem  / 10.24px	
	0.512rem / 8.19px	
	0.41rem  / 6.55px	
	0.328rem / 5.24px	
	0.262rem / 4.19px	
	0.21rem  / 3.36px	
	0.168rem / 2.68px	
	0.134rem / 2.15px	
	0.107rem / 1.72px
	0.086rem /1.37px
	0.069rem /1.10px
*/