.videoActionsContainer {
	display: flex;
	gap: 0.976rem;
	padding: 0.976rem 0.976rem;
}

.videoActionsContainer .btn {
	border-radius: 0.134rem;
	background-color: #efe9e0;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-grow: 1;
	height: 2.188rem;
/*	color: #efe9e0;*/
/*	lets it grow when there is extra space. */
/*	flex-shrink: 1;*/
/*	lets it shrink when there is not enough space. */
/*	flex-basis: 10px */
/*	allows it to have width defined by its content with respect to its container's width.*/
}

.videoActionsContainer .btn:hover {
	cursor: pointer;
/*	background-color: #ffffff22;*/
}
.icon {
	font-size: 1rem;
	margin-left: 5px;
}


@media only screen and (max-width: 768px) {
	.videoActionsContainer { 
		gap: 0.64rem;
		padding: 0.64rem 0.64rem;
	}
}

@media only screen and (max-width: 430px) {
	.videoActionsContainer .btn {
		height: 1.862rem;
		font-size: 0.8rem;
	}
	.icon { font-size: 1rem; }
}

@media only screen and (max-width: 375px) {
	.videoActionsContainer .btn {
		height: 1.49rem;
		font-size: 0.64rem;
	}
	.icon { font-size: 0.8rem; }
}

@media only screen and (max-width: 320px) {
	.videoActionsContainer { 
		gap: 0.41rem;
		padding: 0.41rem 0.64rem;
	}
	.videoActionsContainer .btn {
		height: 1.192rem;
		font-size: 0.512rem;
	}
	.icon { font-size: 0.64rem; }
}


/* 
	https://typescale.com
	SCALE: 1.250 

	11.642rem/	
	9.313rem /
	7.451rem / 119.21px
	5.96rem  / 95.37px
	4.768rem / 76.29px
	3.815rem / 61.04px	
	3.052rem / 48.83px	
	2.441rem / 39.06px	
	1.953rem / 31.25px	
	1.563rem / 25.00px	
	1.25rem  / 20.00px	
	1rem 	 / 16.00px	
	0.8rem   / 12.80px	
	0.64rem  / 10.24px	
	0.512rem / 8.19px	
	0.41rem  / 6.55px	
	0.328rem / 5.24px	
	0.262rem / 4.19px	
	0.21rem  / 3.36px	
	0.168rem / 2.68px	
	0.134rem / 2.15px	
	0.107rem / 1.72px
	0.086rem /1.37px
	0.069rem /1.10px
*/