* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

#root {
    position: relative;
}

body {
    background-color: #000;
}

div {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}
